<template>
    <div class="page-container">
        <div class="section mission-section">
            <img src="https://ai.modehoist.com/gowow/website/241028/homepage/mission1.png" alt="Mission Image" />
            <div class="mission-intro">
                <h2>{{ $t("index.我们的使命") }}</h2>
                <p>
                    {{ $t("index.打破传统") }}<br><br>
                    {{ $t("index.致力于内部的研究与开发") }}<br><br>
                    {{ $t("index.为每次冒险赋予可持续创新的力量") }} <br><br>
                    {{ $t("index.用电动动力重新定义越野体验") }}<br><br>
                    {{ $t("index.为每位骑手提供无与伦比的安全性、便利性和智能性") }}
                </p>
            </div>
        </div>

        <div class="section comments-section">
            <img src="https://ai.modehoist.com/gowow/website/241028/homepage/mission2.png" alt="Top Image" />
            <div class="comments-list">
                <ul>
                    <li>
                        <div class="comment-block">
                            <div class="comment-header">
                                <img src="https://ai.modehoist.com/gowow/website/241028/homepage/radmoto.png" alt="User Icon" class="icon" />
                                <h3>Radmotousa</h3>
                            </div>
                            <div class="comment-details">
                                {{
                                    $t("index.作为一家个人电动交通工具店和定制商。这是我们迄今为止体验过的最佳开箱体验。所有方面都经过精心设计，从优质工具包到最少的组装，再到为各种条件而设计的自行车，均可以优雅地骑行。我们对这辆自行车的卓越性能感到震惊，并且通过App甚至可以锁定模式！")
                                }}
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="comment-block">
                            <div class="comment-header">
                                <img src="https://ai.modehoist.com/gowow/website/241028/homepage/RideApart.png" alt="User Icon" class="icon" />
                                <h3>RideApart</h3>
                            </div>
                            <div class="comment-details">
                                {{ $t("index.Ori有一些令人印象深刻的规格可以展示") }} <br> 
                                {{ $t("index.一款令人兴奋的新电动越野摩托车") }}
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "~@assets/styles/func.scss";

.page-container {
    display: flex;
    flex-direction: column;
    padding: px2rem(20px);
    background-color: #f8f8f8;
}

.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: px2rem(20px);
}

.mission-intro {
    padding: px2rem(10px);
    text-align: center;
    border-radius: px2rem(8px);

    p {
        font-size: px2rem(18px);
        color: #666;
        line-height: px2rem(24px);
    }

    h2 {
        margin-bottom: px2rem(10px);
        font-size: px2rem(22px);

    }
}

img {
    width: 100%;
    height: auto;
    border-radius: px2rem(8px);
}

.comments-list ul {
    list-style: none;
    padding: 0;
}

.comments-list li {
    margin-top: px2rem(20px);

    p {
        line-height: 1.6em;
        font-size: px2rem(14px);
    }
}

.comment-block {
    display: flex;
    flex-direction: column;
    gap: px2rem(10px);
    padding: px2rem(15px);
    background: #f1f1f1;
    border-radius: px2rem(8px);
    box-shadow: 0 px2rem(2px) px2rem(4px) rgba(0, 0, 0, 0.1);
}

.comment-header {
    display: flex;
    align-items: center;
    gap: px2rem(12px);

    h3 {
        margin: 0;
        font-size: px2rem(18px);
        font-weight: bold;
    }

    .icon {
        width: px2rem(30px);
        height: px2rem(30px);
        border-radius: 50%;
    }
}

.comment-details {
    margin: 0;
    color: #666;
    line-height: px2rem(24px);
    font-size: px2rem(16px);
}
</style>