<template>
    <div class="tab-news">
        <div class="title">{{ $t("index.新闻与新闻发布") }}</div>
        <template v-if="loading">
            <div style="height: 293px; width: 100%; font-size: 100px" v-loading="loading"
                element-loading-text="loading..." element-loading-background="transparent"></div>
        </template>
        <template v-else-if="list.length">
            <div class="list">
                <div class="item" v-for="item in list" :key="item.id">
                    <div class="item-inner" @click="onItemClick(item.uid)">
                        <img :src="item.cover" alt="News_picture" class="cover" />
                        <div class="content">
                            <div class="news-title">{{ item.title }}</div>
                            <div class="time">{{ item.createTime }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <el-empty></el-empty>
        </template>
    </div>
</template>

<script>
import { front_news_list } from "@/service/api";
import { goto } from "@/utils/goto";
import { NEWS_ID } from "../../brand/news/constants";

export default {
    data() {
        return {
            loading: true,
            list: [],
        };
    },
    mounted() {
        this.fetchList();
    },
    methods: {
        onItemClick(uid) {
            goto(`/brand/newsdetail/${uid}`);
        },
        async fetchList() {
            this.loading = true;
            try {
                const params = {
                    categoryId: NEWS_ID,
                    pageNum: 1,
                    pageSize: 3, // 只请求3条新闻
                };
                const { items } = (await front_news_list(params)) || {};
                this.list = items;
            } catch (e) {
                console.log(e);
            }
            this.loading = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@assets/styles/vars.scss";
@import "~@assets/styles/mixin.scss";
@import "~@assets/styles/func.scss";

@media (min-width:$screen-md-min) {
    .tab-news {
        max-width: 100%;
        margin: 0 auto;
        padding: 50px 10px 120px 10px ;
        .title {
            text-align: center;
            font-size: 36px;
            font-weight: bold;
            line-height: 120px;
        }
        .list {
            display: flex;
            justify-content: space-between;
        }

        .item {
            width: 32%;
            display: flex;

            .item-inner {
                display: flex;
                flex-direction: column;
                cursor: pointer;

                .cover {
                    width: 100%;
                    height: 26vw;
                    object-fit: cover;
                }
                .content{
                    
                    .news-title {
                        margin-top: 30px;
                        font-size: 22px;
                        color: #000;
                        line-height: 32px;
                        text-align: left;
                        font-weight: normal;
                    }

                    .time {
                        margin-top: 12px;
                        font-size: 14px;
                        color: rgba(150, 150, 150, 1);
                        line-height: 20px;
                        text-align: left;
                    }
                }
                
            }
        }
    }
}
@media (max-width: $screen-md-min) {
    .tab-news {
        max-width: 100%;
        margin: 0 auto;
        padding: px2rem(40px) px2rem(10px) px2rem(40px) px2rem(10px);

        .title {
            text-align: center;
            font-size: px2rem(24px);
            font-weight: bold;
            line-height: px2rem(42px);
        }

        .list {
            display: flex;
            flex-direction: column;
        }

        .item {
            padding: px2rem(10px);
            width: 100%;
            display: flex;

            .item-inner {
                display: flex;
                flex-direction: column;
                cursor: pointer;

                .cover {
                    width: 100%;
                    height: 80vw;
                    object-fit: cover;
                }

                .content {
                    text-align: left;
                    .news-title {
                        margin-top: px2rem(20px);
                        font-size: px2rem(18px);
                        color: #000;
                        line-height: px2rem(24px);
                    }

                    .time {
                        margin-top: px2rem(10px);
                        font-size: px2rem(14px);
                        color: rgba(150, 150, 150, 1);
                        line-height: px2rem(20px);
                    }
                }
            }
        }
        }

}

</style>