<template>
  <div class="page-container">
    <div class="section left-section">
      <div class="mission-intro">
        <h2>{{ $t("index.我们的使命") }}</h2>
        <p>
          {{ $t("index.打破传统") }}<br>
          {{ $t("index.致力于内部的研究与开发") }}<br>
          {{ $t("index.为每次冒险赋予可持续创新的力量") }} <br>
          {{ $t("index.用电动动力重新定义越野体验") }}<br>
          {{ $t("index.为每位骑手提供无与伦比的安全性、便利性和智能性") }}
        </p>
      </div>
      <img src="https://ai.modehoist.com/gowow/website/241028/homepage/mission1.png" alt="Mission Image" />
    </div>

    <div class="section right-section">
      <img src="https://ai.modehoist.com/gowow/website/241028/homepage/mission2.png" alt="Top Image" />
      <div class="comments-section">
        <ul>
          <li>
            <div class="comment-block">
              <img src="https://ai.modehoist.com/gowow/website/241028/homepage/radmoto.png" alt="User Icon" class="icon" />
              <div class="comment-details">
                <h3>Radmotousa</h3>
                <p>
                  {{
                    $t("index.作为一家个人电动交通工具店和定制商。这是我们迄今为止体验过的最佳开箱体验。所有方面都经过精心设计，从优质工具包到最少的组装，再到为各种条件而设计的自行车，均可以优雅地骑行。我们对这辆自行车的卓越性能感到震惊，并且通过App甚至可以锁定模式！")
                  }}
                </p>
              </div>
            </div>
          </li>
          <li>
            <div class="comment-block">
              <img src="https://ai.modehoist.com/gowow/website/241028/homepage/RideApart.png" alt="User Icon" class="icon" />
              <div class="comment-details">
                <h3>RideApart</h3>
                <p>{{ $t("index.Ori有一些令人印象深刻的规格可以展示") }} <br>
                  {{ $t("index.一款令人兴奋的新电动越野摩托车") }}
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
.page-container {

  display: flex;
  gap: 60px;
  padding: 30px 20px 60px 20px;
  justify-content: center;
  background-color: #f8f8f8;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  gap: 20px;
  align-items: center;
}

.right-section {
  align-items: flex-start;
}

.left-section {
  align-items: flex-end;
}

.mission-intro {
  padding: 10px;
  border-radius: 8px;
  text-align: left;
  width: 73%;
  p {
    text-align: left;
    font-size: 1.05vw;
    line-height: 3.5vw;
    color: #666;

  }

  h2 {
    line-height: 3.3w;
    margin-bottom: 10px;
    font-size: 2.2vw;
  }
}


img {
  width: 72%;
  height: auto;
  // border-radius: 8px;
}

.comments-section {
  padding: 10px;
  border-radius: 8px;
}

.comments-section h2 {
  margin-bottom: 10px;
}

.comments-section ul {
  list-style: none;
  padding: 0;
}

.comments-section li {
  margin-top: 30px;

  p {
    line-height: 1.6vw;
    font-size: 1.0vw;
  }
}

.comment-block {
  width: 80%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  background: #f1f1f1;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}

.icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.comment-details h3 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.comment-details p {
  margin: 0;
  color: #666;
  line-height: 28px;
}
</style>