<template>
    <div class="wrap">
      <div class="bgimg">
        <img src="https://ai.modehoist.com/gowow/website/AlphaDetailAPP/06.png" />
      </div>
      <div class="box">
        <div class="title">
          <p>Alpha</p>
          <p v-if="isCN">{{ $t("index.全地形电动摩托车") }}</p>
          <p v-else>{{ $t("index.生来为越野而生，在城市丛林中出类拔萃") }}</p>
        </div>
        <ul class="smalltitle" v-if="isCN">
          <div class="top">
            <li class="active" @click="product">
              {{ $t("index.了解") }} Alpha
            </li>
            <li @click="gotrial">
              {{ $t("index.预约试驾") }} Alpha
            </li>
          </div>
          <li @click="add" class="bottom" >
            {{ $t("index.定购") }} Alpha
          </li>
        </ul>
        <!-- 国外版本只需下面一个按钮链接 -->
        <div class="explore" v-else @click="product" >
          {{ $t("index.探索更多") }}
        </div>
      </div>
      <Float ref="Float" v-if="false" />
    </div>
  </template>
  
  <script>
  import { goto } from "@utils/goto";
  import Float from "../../product/Alpha/components/Float";
  import { mapState } from "vuex";
  
  export default {
    components: {
      Float,
    },
    computed: {
      ...mapState(["lang", "profile"]),
      isCN() {
        return this.lang === "zh-CN";
      },
    },
    methods: {
      gotrial() {
        goto("/trial");
      },
      add() {
        goto("/buy");
      },
      product() {
        goto("/product/Alpha");
      },
    },
  };
  </script>
  
  <style lang='scss' scoped>
  @import "~@assets/styles/func.scss";
  
  .wrap {
    position: relative;
    width: 100vw;
    .bgimg {
      img {
        margin-top: 42%;
        width: 100%;
        vertical-align: top;
      }
    }
    .box {
      position: absolute;
      top: 0;
      width: 100vw;
      text-align: center;
      .title {
        margin-top: px2rem(10px);
        font-family: PingFang-SC-Bold;
        font-weight: bold;
        font-size: px2rem(20px);
        line-height: px2rem(28px);
      }
      .smalltitle {
        width: 100%;
        justify-content: center;
        .bottom {
          margin: 0 auto;
        }
        .top {
          display: flex;
          justify-content: center;
          li {
            margin: px2rem(8px) px2rem(8px);
          }
        }
        li {
          margin: px2rem(8px) 0;
          font-size: px2rem(14px);
          line-height: px2rem(36px);
          border: 1px solid #000000;
          border-radius: px2rem(18px);
          width: 40vw;
        }
        li:hover {
          cursor: pointer;
        }
        .active {
          background-color: #000000;
          color: #ffffff;
        }
      }
      .explore{
        margin: px2rem(10px) auto;
        font-size: px2rem(16px);
        line-height: px2rem(36px);
        border: 1px solid #000000;
        border-radius: px2rem(18px);
        width: 70vw;
        &:hover{
          cursor: pointer;
          background-color: #000000;
          color: #ffffff;
        }
      }
    }
  }
  </style>