<template>
  <div class="wrap">
    <img src="../../../assets/imgs/knowORI.png" />

    <div class="box">
      <div class="title" v-if="isCN">
        {{ $t("index.猎户座") }}-{{ $t("index.全地形电动摩托车") }}
      </div> 
      <div class="title" v-else>
        ORI - {{$t("index.更强大的性能，解锁您的真正越野体验")}}
      </div>
      <ul class="smalltitle" v-if="isCN">
        <li class="active" @click="product">
          {{ $t("index.了解") }} {{ $t("index.猎户座") }}
        </li>
        <li @click="gotrial">
          {{ $t("index.预约试驾") }} {{ $t("index.猎户座") }}
        </li>
        <li @click="add" v-if="isCN">
          {{ $t("index.定购") }} {{ $t("index.猎户座") }}
        </li>
      </ul>
      
    </div>
    <!-- 国外版本需要增加以下内容 -->
    <div class="left" v-if="!isCN">
      <div class="version">
        {{$t("index.越野版本")}}:
      </div>
      <div class="parameters">
        <div class="para-item">
          <div class="para-title">{{ $t("slides.续航里程") }}</div>
          <div class="para-content">100km</div>
        </div>
        <div class="para-item">
          <div class="para-title">{{ $t("slides.最高速度") }}</div>
          <div class="para-content">100km/h</div>
        </div>
        <div class="para-item">
          <div class="para-title">{{ $t("slides.峰值功率") }}</div>
          <div class="para-content">9kW</div>
        </div>
      </div>
      <div class="supplement">
        *{{$t("index.街道版本现已推出")}}
      </div>
    </div>
    
    <div class="right" v-if="!isCN"> 
      <div class="see-video-btn" @click="product">
        {{$t("index.探索更多")}}
      </div>
    </div>
    <Float ref="Float" v-if="false" />
  </div>
</template>

<script>
import { goto } from "@utils/goto";
import Float from "../../product/ORI/components/Float";
import { mapState } from "vuex";

export default {
  components: {
    Float,
  },
  computed: {
    ...mapState(["lang", "profile"]),
    isCN() {
      return this.lang === "zh-CN";
    },
  },
  methods: {
    gotrial() {
      goto("/trial");
    },
    add() {
      goto("/buy");
      // goto("/order/ProductDetails");
    },
    product() {
      goto("/product/ORI");
    },
  },
};
</script>

<style lang='scss' scoped>
.wrap {
  position: relative;
  overflow: hidden;
}

img {
  margin-top: -30px;
  width: 100%;
}
img {
  vertical-align: top;
}
.right{
  position: absolute;
  right: 20px;
  bottom: 200px;
  .see-video-btn {
    background-color: transparent;
    font-weight: bold;
    color: gray;
    padding: 10px 20px;
    border-radius: 10px;
    margin-right: 50px;
    border: gray solid 2px;
    &:hover {
      cursor: pointer;
      background-color: gray;
      color: white;
    }
  }
}
.left {
  position: absolute;
  left: 10px;
  bottom: 180px;
  margin-left: 50px;
  color: gray;
  width: 400px;
  .version {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .parameters{
    display: flex;
    .para-item {
      
      margin-right:30px;
      display: flex;
      flex-direction: column;
      .para-title {
        font-size: 17px;
        // font-weight: bold;
        margin-bottom: 10px;
      }
      .para-content {
        font-size: 15px;
      }
    }
  }
  .supplement {
    margin-top: 20px;
    font-size: 14px;
    line-height: 24px;
  }
  
}
.box {
  position: absolute;
  top: 0;
  width: 100vw;
  text-align: center;
  padding-top: 30px;
  .title {
    font-family: PingFang-SC-Bold;
    font-weight: bold;
    font-size: 30px;
    line-height: 120px;
  }
  .smalltitle {
    width: 100%;
    justify-content: center;
    display: flex;
    li {
      margin: 0 7vw;
      font-size: 18px;
      line-height: 40px;
    }
    li:hover {
      cursor: pointer;
    }
    .active {
      border-bottom: 2px solid black;
    }
  }
}
</style>