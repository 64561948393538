<template>
  <div class="wrap">
    <div class="bgimg">
      <img src="@assets/imgs/homeM/backgroundimg.png" v-if="num==1"/>
      <img src="https://ai.modehoist.com/gowow/website/241028/homepageM/backgroundAlpha.png" v-if="num==2"/>
    </div>
    <div class="content">
      <!-- 这里只有是alpha（num=2）的时候才标出名字，而ORI背景图片中就有名字 -->
      <div class="title" v-if="num==2">
        <div>GOWOW ALPHA</div>
      </div>
      <!-- 国外展示slogan即可，国内展示链接 -->
      <div class="slogan" v-if="!isCN" >
        <div v-if="num==2">{{$t("index.保持野性，保持充电")}}</div>
        <div v-else>{{$t("index.原装Gowow体验")}}</div>
      </div>
      <div class="btn" v-else>
        <div class="see-video-btn" @click="product">
          {{ $t("index.了解详情") }}
        </div>
        &nbsp;
        <div class="see-video-btn" @click="gotrial">
          {{ $t("index.预约试驾") }}
        </div>
      </div>
    </div>
  </div>
</template>
        
<script>
import { goto } from "@utils/goto";
import { mapState } from "vuex";
export default {
  props: {
    num: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {};
  },
  computed:{
    ...mapState(["lang"]),
    isCN() {
      return this.lang === "zh-CN";
    },
  },
  methods: {
    product() {
      goto("/product/ORI");
    },
    gotrial() {
      goto("/trial");
    },
  },
};
</script>

   <style lang='scss' scoped>
@import "~@assets/styles/vars.scss";
@import "~@assets/styles/mixin.scss";
@import "~@assets/styles/func.scss";
* {
  padding: 0;
  margin: 0;
}
img {
  vertical-align: top;
}
.wrap {
  position: relative;
}
.btn {
  display: flex;
  justify-content: center;
}
.content {
  position: absolute;
  top: 50%;
  color: #ffffff;
  width: 100%;
  text-align: center;

  .title {
    
    font-family: PingFang-SC-Medium;
    font-size: px2rem(42px);
    line-height: px2rem(60px);
  }
  .slogan {
    
    font-family: PingFang-SC-Medium;
    font-size: px2rem(24px);
    line-height: px2rem(32px);
    font-weight: 200;
    margin-top: px2rem(20px);
    
  }
  .see-video-btn {
    cursor: pointer;
    margin-top: px2rem(20px);
    width: px2rem(120px);
    height: px2rem(40px);
    line-height: px2rem(35px);
    border: 2px solid rgba(255, 255, 255, 1);
    font-size: px2rem(16px);
    font-weight: 200;
    font-family: PingFang-SC-Medium;
    border-radius: px2rem(20px);
  }
}
.bgimg {
  img {
    width: 100%;
  }
}
</style>