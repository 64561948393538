<template>
  <div>
    <div class="wrap">
      <div class="btn-wrap" @click="onBtnClick"></div>
    </div>
    <template>
      <el-dialog
        v-if="platform.isPC"
        :visible.sync="show"
        :title="$t('product.订购方式')"
        width="500px"
      >
        <el-radio-group v-model="type">
          <el-radio v-for="item in actions" :key="item.val" :label="item.val">
            {{ item.name }}
          </el-radio>
        </el-radio-group>
        <span slot="footer" class="dialog-footer">
          <el-button @click="show = false">{{ $t("product.取消") }}</el-button>
          <el-button type="primary" @click="onConfirm">{{
            $t("product.确定")
          }}</el-button>
        </span>
      </el-dialog>
      <van-action-sheet
        v-else
        v-model="show"
        :actions="actions"
        :cancel-text="$t('product.取消')"
        @select="onSelect"
      />
    </template>
  </div>
</template>

<script>
import { goto } from "@utils/goto";
import langMixin from "@/mixin/lang";
export default {
  mixins: [langMixin],
  computed: {
    actions() {
      return [
        { name: this.$t("product.预付定金"), val: "booking" },
        { name: this.$t("product.全款订购"), val: "full" },
      ];
    },
  },
  data() {
    return {
      show: false,
      type: "booking", // booking | full
    };
  },
  methods: {
    onBtnClick() {
      this.type = "booking";
      this.show = true;
    },
    onConfirm() {
      this.show = false;
      goto(`/order/add?type=${this.type}`);
    },
    onSelect(item) {
      this.show = false;
      goto(`/order/add?type=${item.val}`);
    },
  },
};
</script>

<style lang='scss' scoped>
@import "~@assets/styles/vars.scss";
@import "~@assets/styles/mixin.scss";
@import "~@assets/styles/func.scss";

// pc端
@media (min-width: $screen-md-min) {
  .m {
    display: none;
  }
  .wrap {
    cursor: pointer;
    position: fixed;
    bottom: 30px;
    right: 10px;
    z-index: 99;
    .btn-wrap {
      //   background: #424741;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 15px;
      border-radius: 55px;
      font-weight: bold;
      .motuo {
        color: yellow;
        font-size: 30px;
        margin-right: 10px;
      }
      .btn-text {
        color: #fff;
        font-size: 24px;
      }
    }
  }
}
// m端
@media (max-width: $screen-md-min) {
  .pc {
    display: none;
  }
  .wrap {
    position: fixed;
    bottom: 80px;
    right: px2rem(2px);
    z-index: 99;
    .btn-wrap {
      //   background: #424741;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: px2rem(35px);
      padding: 0 px2rem(15px);
      font-weight: bold;
      .motuo {
        color: yellow;
        font-size: px2rem(20px);
        margin-right: px2rem(10px);
      }
      .btn-text {
        color: #fff;
        font-size: px2rem(14px);
      }
    }
  }
}
</style>
