//  口碑文章
export const ARTICLE_ID = '130b2aceb04e4a319e0c47d094ba4b45';
//  视频库
export const VIDEO_ID = '45f8516d40454ca883ee319dd0e2a73e';
//  新闻资讯
export const NEWS_ID = '5ae89ad4dd784f9eb18de65d9fe3b02d';
//  用户实拍
export const PHOTO_ID = '6bce5c23204a4fd3abd41e2e9a916564';
//  媒体评测
export const EVALUATE_ID = 'b5f219979bc54500a0ac56a25859689b';
