<template>
  <div class="wrap">
    <img 
      class="alpha-image"
      src="https://ai.modehoist.com/gowow/website/AlphaDetail/Vehicle.png" 
      v-if="isCN"
    />

    <div class="box">
      <div class="title" v-if="isCN">
        Alpha-{{ $t("index.全地形电动摩托车") }}
      </div>
      <div class="title" v-else>
        Alpha - {{ $t("index.生来为越野而生，在城市丛林中出类拔萃") }}
      </div>
      <ul class="smalltitle" v-if="isCN">
        <li class="active" @click="product">
          {{ $t("index.了解") }} Alpha
        </li>
        <li @click="gotrial">
          {{ $t("index.预约试驾") }} Alpha
        </li>
        <li @click="add">
          {{ $t("index.定购") }} Alpha
        </li>
      </ul>  
    </div>    
    <!-- 国内版本和国外版本KnowAlpha 出入较大，以下是国外版本使用的滑动窗口 -->
    <div class="comparison-slider" ref="slider" v-if="!isCN">
      <!-- Right Image Container -->
      <div class="image-container right-image-container">
        <div class="overlay-text right-text">
          <div class="version">
            {{ $t("index.街道版本") }}:
          </div>
          <div class="para-list">
            <div class="para-item">
              <div class="para-title">{{ $t("slides.续航里程") }}</div>
              <div class="para-content">75km</div>
            </div>
            <div class="para-item">
              <div class="para-title">{{ $t("slides.最高速度") }}</div>
              <div class="para-content">45km/h</div>
            </div>
            <div class="para-item">
              <div class="para-title">License</div>
              <div class="para-content">AM/B(L1e-B)</div>
            </div>
          </div>
        </div>
        <img src="https://ai.modehoist.com/gowow/website/241028/homepage/cityBackground.png" alt="Right Image" class="image right-image"
          @dragstart.prevent draggable="false" />
      </div>
      <!-- Left Image Container -->
      <div class="image-container left-image-container" :style="{ width: rightImageWidth }">
        <img src="https://ai.modehoist.com/gowow/website/241028/homepage/fieldBackground.png" alt="Left Image" class="image left-image"
          @dragstart.prevent draggable="false" />
        <div class="overlay-text left-text">
          <div class="version">
            {{ $t("index.越野版本") }}:
          </div>
          <div class="para-list">
            <div class="para-item">
              <div class="para-title">{{ $t("slides.最大扭矩") }}</div>
              <div class="para-content">420Nm</div>
            </div>
            <div class="para-item">
              <div class="para-title">{{ $t("slides.最高速度") }}</div>
              <div class="para-content">80km/h</div>
            </div>
            <div class="para-item">
              <div class="para-title">{{ $t("slides.峰值功率") }}</div>
              <div class="para-content">8kW</div>
            </div>
          </div>
        </div>
      </div>

      <div class="slider-handle" @mousedown="startDrag" @touchstart="startDrag" :style="{ left: handlePosition }">
        <img src="@assets/imgs/dragger_icon.png" alt="Slider Icon" class="slider-icon" @dragstart.prevent
          draggable="false" />
        <div v-show="showprompt" class="slider-text">{{ $t("index.点击并拖动") }}</div>
      </div>

      <div class="explore_button" @click="product">
        {{ $t("index.探索更多") }}
      </div>
    </div>

    <Float ref="Float" v-if="false" />
  </div>
</template>

<script>
import { goto } from "@utils/goto";
import Float from "../../product/Alpha/components/Float";
import { mapState } from "vuex";

export default {
  components: {
    Float,
  },
  data() {
    return {
      showprompt: true,
      isDragging: false,
      rightImageWidth: '50%',
      handlePosition: '50%',
    };
  },
  computed: {
    ...mapState(["lang", "profile"]),
    isCN() {
      return this.lang === "zh-CN";
    },
  },
  methods: {
    gotrial() {
      goto("/trial");
    },
    add() {
      goto("/buy");
      // goto("/order/ProductDetails");
    },
    product() {
      goto("/product/Alpha");
    },
    startDrag() {
      this.isDragging = true;
      this.showprompt = false;
      document.body.style.cursor = 'ew-resize';
      window.addEventListener('mousemove', this.onDrag);
      // window.addEventListener('touchmove', this.onDrag);
      window.addEventListener('mouseup', this.stopDrag);
      // window.addEventListener('touchend', this.stopDrag);
    },
    stopDrag() {
      this.isDragging = false;
      document.body.style.cursor = 'default';
      window.removeEventListener('mousemove', this.onDrag);
      // window.removeEventListener('touchmove', this.onDrag);
      window.removeEventListener('mouseup', this.stopDrag);
      // window.removeEventListener('touchend', this.stopDrag);
    },
    onDrag(e) {
      if (!this.isDragging) return;
      const sliderRect = this.$refs.slider.getBoundingClientRect();
      let clientX;
      if (e.type.includes('touch')) {
        clientX = e.touches[0].clientX;
      } else {
        clientX = e.clientX;
      }
      let offsetX = clientX - sliderRect.left;
      offsetX = Math.max(0, Math.min(offsetX, sliderRect.width));
      const percent = (offsetX / sliderRect.width) * 100;
      this.rightImageWidth = `${percent}%`;
      this.handlePosition = `${percent}%`;
    },
  },
};
</script>

<style lang='scss' scoped>



.wrap {
  padding-top: 120px;
  margin-bottom: 100px;
  position: relative;
  text-align: center;
  overflow: hidden;
  .alpha-image {
    width: 65%;
    margin-top: 6%;
    vertical-align: top;
  }
 
  
  .box {
    position: absolute;
    top: 0;
    width: 100vw;
    text-align: center;
    padding-top: 30px;
    .title {
      font-family: PingFang-SC-Bold;
      font-weight: bold;
      font-size: 30px;
      line-height: 120px;
    }
    .smalltitle {
      width: 100%;
      justify-content: center;
      display: flex;
      li {
        margin: 0 7vw;
        font-size: 18px;
        line-height: 40px;
      }
      li:hover {
        cursor: pointer;
      }
      .active {
        border-bottom: 2px solid black;
      }
    }
  }
  
 
  .comparison-slider {
    position: relative;
    width: 82vw;
    margin: 0 auto;
    user-select: none;
    height: 45vw;
    border-radius: 30px;
    overflow: hidden;
    .image-container {
      position: absolute;
      top: 0;
      height: 100%;
      overflow: hidden;
      .image {
        width: 82vw;
        height: auto;
      }
      


      .overlay-text {
        position: absolute;
        padding: 10px 0 10px 30px;
        text-align: left;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        .version {
          
          font-size: 17px;
          font-weight: bold;
          color: white;
          margin-bottom: 20px;
        }
        .para-list{
          display: flex;
          justify-content: space-around;
          .para-item {
            color: rgb(247, 245, 245);
            margin-right: 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .para-title {
              font-size: 17px;
              // font-weight: bold;
              margin-bottom: 10px;
            }

            .para-content {
              font-size: 15px;
            }
          }
        }
        
      }

      .right-text {
        position: absolute;
        right: 10px;
        top: 50px;
        z-index: 5;
        width: 430px;
      }

      .left-text {
        position: absolute;
        left: 10px;
        top: 50px;
        z-index: 5;
        width: 430px;

      }
    }

    .left-image-container {
      z-index: 2;
    }

    .right-image-container {
      z-index: 1;
    }

    .slider-handle {
      position: absolute;
      top: 78%;
      cursor: ew-resize;
      z-index: 4;
      transform: translateX(-50%);
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: white;

      img {
        margin-bottom: 10px;
      }
    }
    .explore_button {
      position: absolute;
      right: 0px;
      bottom: 20px;
      z-index: 4;
      background-color: transparent;
      font-weight: bold;
      color: white;
      padding: 10px 20px;
      border-radius: 10px;
      margin-right: 20px;
      border: white solid 2px;

      &:hover {
        cursor: pointer;
        background-color: gray;
        color: white;
      }
    }
  }
}




</style>